<template>
    <div></div>
</template>

<script>
    export default {
        name: 'GoogleMapMarker',

        props: {
            google: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            marker: {
                type: Object,
                required: true
            },
            cluster: {
                default: null
            },
            editable: {
                default: false,
            },
        },

        data() {
            return {
                google_marker: null,
            }
        },

        mounted() {
            this.refreshMarker();

            if ( this.cluster ) {
                this.cluster.addMarker(this.google_marker);
            }
        },

        destroyed() {
            if ( this.cluster ) {
                this.cluster.removeMarker(this.google_marker);
            }

            this.destroyMarker();
        },

        methods: {
            refreshMarker() {
                this.destroyMarker();

                this.google_marker = new this.google.maps.Marker({
                    position: this.marker.position,
                    marker: this.marker,
                    map: this.map,
                    icon: {
                        ...this.marker.icon,
                        scaledSize: new this.google.maps.Size(30, 30)
                    },
                    draggable: this.editable,
                });

                this.google_marker.addListener('click', () => {
                    this.$emit('marker-click');
                });

                if ( this.editable ) {
                    this.google_marker.addListener('drag', () => {
                        this.$emit('marker-updated', {
                            lat: Math.round(this.google_marker.position.lat() * 100000) / 100000,
                            lng: Math.round(this.google_marker.position.lng() * 100000) / 100000,
                        });
                    });
                }

                this.$emit('marker-loaded', this.google_marker);
            },

            destroyMarker() {
                if ( this.google_marker ) {
                    this.google_marker.setMap(null);
                    this.google_marker = null;
                }
            },
        },

        watch: {
            'marker.position'() {
                this.refreshMarker();
            },

            'marker.icon.url'() {
                this.refreshMarker();
            },

            editable() {
                this.refreshMarker();
            },
        },
    }
</script>
