import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'user_type',
            'user_types',
            'user_type_access',
            'has_access_to_role',
            'user_id',
            'user_display',
            'user_role_id',
            'username',
            'is_system_admin',
            'is_impersonating',
            'permissions',
        ]),

        isSubContractor() {
            return this.user_type === 'sub_contractor';
        },

        isField() {
            return this.user_type === 'field';
        },

        isFieldOrSubContractor() {
            return this.isSubContractor || this.isField;
        },

        isAdmin() {
            return this.user_type === 'admin';
        },

        isAccountAdmin() {
            return this.user_type === 'account_admin';
        },
    },
}