<template>
    <div >
        <div class="google-map" ref="google_map" :style="mapStyle"></div>
        <template v-if="Boolean(this.google) && Boolean(this.map)">
            <slot
                :google="google"
                :map="map"
            />
        </template>
    </div>
</template>

<script>
    import GoogleMapsApiLoader from 'google-maps-api-loader';

    export default {
        name: 'GoogleMapLoader',

        props: {
            mapConfig: Object,
            apiKey: String,

            mapStyle: {
                type: Object,
                default() {
                    return {
                        height: '400px',
                        width: '100%'
                    }
                }
            },
            mapCenter: {
                type: Object,
                required: true,
                validate(mapCenter) {
                    return 'lat' in mapCenter && 'lng' in mapCenter;
                },
            },
            mapZoom: {
                type: Number,
                default: 8,
            },
        },

        data() {
            return {
                google: null,
                map: null,
            }
        },

        async mounted() {
            this.google = await GoogleMapsApiLoader({
                apiKey: this.apiKey,
            });

            this.initializeMap();
        },

        methods: {
            initializeMap() {
                this.map = new this.google.maps.Map(
                    this.$refs.google_map,
                    {
                        center: {...this.mapCenter},
                        zoom: this.mapZoom,
                        ...this.mapConfig
                    }
                );

                this.$emit('map-loaded', {
                    map: this.map
                });
            },

            resetCenter() {
                this.$nextTick(() => {
                    this.map?.panTo(this.mapCenter);
                });
            },
        },
    }
</script>
